// HomePage.tsx
import React, { useState } from 'react';
import styles from './CreatorsPage.module.scss';
import { useGetCreatorsQuery, useGetReviewQuery } from '../../state/api';
import { CasinoCategory, CasinoReviewData } from '../../models/ReviewData';
import { NavLink } from 'react-router-dom';
import { Icons, Creator, CreatorData } from '../../models/CreatorsData';
import { CreatorTable } from './CreatorTable';
import { Badge } from '../../components/Badge';
import { ProgramCTA } from '../../components/ProgramCTA';
import { CreatorFAQ } from '../../components/CreatorFAQ';

function CreatorPageHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span>Creator Transparency</span>
        <div className={styles.betaTag}>Beta</div>
      </div>
      <div className={styles.subTitle}>
        <span>A list of creators who have enrolled in Tanzanite's transparency program.</span>
      </div>
    </div>
  )
}

function LiveCreatorCard({creator, icons}: {creator: Creator, icons: Icons}) {
  return (
    <div className={styles.liveCard}> 
      <div className={styles.liveCardPfp}>
        <img width="100px" height="100px" src={creator.pfp ?? ""}/>
        <div className={styles.pfpLevel}>
          <Badge level={creator.verificationLevel} fontSize={22.5} />
        </div>
      </div>
      <a href={creator.live.link} target="_blank" rel="noopener noreferrer">
        <div className={styles.liveCardViewers}>
          <div className={styles.liveIcon}></div>
          <div className={styles.viewersText}>
            {creator.live.viewers?.toLocaleString() ?? "0"} viewers
          </div>
          <div className={styles.linkIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0.5 16 16" fill="none">
              <path d="M6.66696 3.83268H5.46696C4.72022 3.83268 4.34658 3.83268 4.06136 3.97801C3.81048 4.10584 3.60665 4.30966 3.47882 4.56055C3.3335 4.84576 3.3335 5.21941 3.3335 5.96615V11.0328C3.3335 11.7795 3.3335 12.1527 3.47882 12.4379C3.60665 12.6888 3.81048 12.893 4.06136 13.0208C4.3463 13.166 4.71949 13.166 5.46477 13.166H10.5356C11.2808 13.166 11.6535 13.166 11.9384 13.0208C12.1893 12.893 12.3938 12.6886 12.5216 12.4377C12.6668 12.1528 12.6668 11.78 12.6668 11.0347V9.83268M13.3335 6.49935V3.16602M13.3335 3.16602H10.0002M13.3335 3.16602L8.66683 7.83268" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </a>
      <div className={styles.liveCardName}>
        {creator.name}
      </div>
      <div className={styles.liveCardCasino}>
        <div className={styles.casinoContainer}>
          <img width="18px" height="18px" src={icons[creator.casino] ?? ""}/>
          <span>{creator.casino}</span>
        </div>
      </div>
      <NavLink style={{width: "100%"}} to={"/creators/" + creator.name.toLowerCase()}>
        <div className={styles.liveCardProfile}>
          View Profile
        </div>
      </NavLink>
    </div>
  )
}

function LiveSection({data}: {data: CreatorData | undefined}) {
  const emptyElement = () => {
    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyElement}>
          No enrolled streamers are live.
        </div>
      </div>
    )
  }

  if (!data || !data.creators) return emptyElement();

  const liveStreamers = data.creators.filter(x => x.live.isLive === true);

  if (liveStreamers === undefined || liveStreamers.length === 0) return emptyElement();

  return (
    <div className={styles.liveCards}>
      {liveStreamers
        .sort((a, b) => {
          if (a.verificationLevel !== b.verificationLevel) {
            return b.verificationLevel - a.verificationLevel;
          } else {
            return b.live.viewers - a.live.viewers; // Secondary sort by followers in descending order
          }
        })
        .map((creator, index) => {
          return <LiveCreatorCard key={index} icons={data.icons} creator={creator} />;
        })
      }
    </div>
  )
}

function CreatorsPage() {
  const { data } = useGetCreatorsQuery();

  return (
    <div className="content-container content-main-container ">
      <div className={styles.content}>
        <CreatorPageHeader />
        <div className={styles.sectionTitle}>
          Live Now
          <div className={styles.liveCircle}></div>
        </div>
        <LiveSection data={data} />
        <div className={`${styles.sectionTitle} ${styles.leaderboardTitle}`}>
          Streamers         <div className={styles.streamerTag}>Kick Analytics</div>
        </div>
        <CreatorTable data={data} selectedPlatform={"Kick"} creatorType={'streamers'}/>

        <div className={`${styles.sectionTitle} ${styles.leaderboardTitle}`}>
          Creators
        </div>
        <CreatorTable data={data} selectedPlatform={"X"} creatorType={'creators'}/>
        <ProgramCTA />
        <CreatorFAQ />
      </div>
    </div>
  );
}

export default CreatorsPage;
